import Login from '@/components/sections/views/Login';
import React from 'react';

type Props = {
  cycle: string | undefined;
};

const IndexLogin = (props: Props) => {
  return (
    <Login
      sampleResource="tenants"
      fqcn_bui={{ Bundle: 'securityBundle', Unit: 'login', Interface: 'login' }}
      devUsername="admin"
      devPassword="phpc"
      cycle={props.cycle}
      //devUsername="phprConfigStaging"
      //devPassword="Yfv89fYZBtWUSWRX"
      // devUsername="phpcTeam"
      // devPassword="phpcteam"
    />
  );
};

export function getStaticProps() {
  let cycle = process.env.npm_lifecycle_event;
  if (!cycle) {
    cycle = "dev";
  }

  return {
    props: {
      cycle
    }
  }
}

export default IndexLogin;
